.MuiButtonBase-root{
  padding: 0rem !important;
}

.experience-step{
  height: 16px;
  width:16px;
  background-color: $blue;
  border-radius: 100%;
  margin-left: -.4rem;
  margin-top: -.8rem;
}

.MuiExpansionPanel-root {
  background-color: $light !important;
}

.MuiExpansionPanelDetails-root {
  border-left: 2px solid $blue;
  margin-top: -1.2rem;
  text-align: start;
}

.MuiTypography-body1{
  font-family: $standard !important;
  font-size: 1rem !important;
  font-weight: 100 !important;
  letter-spacing: 2px !important;
  line-height: 1.5rem !important;
}

.experience-section-heading{
  font-size: 1.25rem;
  color: $blue;
  font-family: $headings;
}

.MuiExpansionPanel-root{
  box-shadow: none !important;
}

.experience-title{
  margin-top:-1rem;
  font-size: 1.4rem;
  color: $blue;
}

.experience-link{
  color: $blue;
  text-decoration: none;

  &:hover{
    color: $blue;
  }
}

.experience-section-title{
  background-color: $green;
  height: 6rem;
  font-size: 4rem;
  font-family: $headings;
  align-items:center;
  letter-spacing: 2px;
  width: 100vw;
  color: $light;
}

.experience-list{
  margin-top: 5rem;
  margin-bottom: 5rem;
}